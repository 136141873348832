import axios from 'axios';

const accountSid = process.env.REACT_APP_TWILIO_SID;
const authToken = process.env.REACT_APP_TWILIO_AUTH;
const baseURL = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}`;

export const API_SERVICE = axios.create({
  baseURL,
});

API_SERVICE.interceptors.request.use(function (config) {
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  config.headers.Authorization = 'Basic ' + btoa(`${accountSid}:${authToken}`);
  return config;
});

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      console.log('HTTP error logged in axios');
    }
    return Promise.reject(error);
  }
);