import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import stateAction from "../store/actions/state.action";
import '../sass/utilities.scss';
import '../sass/components/button.scss';
import '../sass/components/text-input.scss';
import '../sass/index.scss';
import { useNavigate } from 'react-router-dom';
import { functions } from "../store/firebase.config";
import { httpsCallable } from "firebase/functions";
import { ITEM_SERVICE } from "../services/item.service";
import { USER_SERVICE } from "../services/user.service";
import { EMAIL_SERVICE } from "../services/email.service";
import { SMS_ENDPOINTS } from "../api/sms.http";
import { CHAT_SERVICE } from "../services/chat.service";
import { API_SERVICE } from "../api/axios.client";

export default function FoundReunite(props) {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const tag = useSelector(redux => redux.state.reuniteTag);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [name, setName] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [emailText, setEmailText] = React.useState('');
    const [itemName, setItemName] = React.useState('');
    const [tagOwnerId, setTagOwnerId]=  React.useState('');
    const [emailChatLink, setEmailChatLink] = React.useState('');
    const handleEmail = httpsCallable(functions, 'sendEmail');
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.backgroundColor = '#F7F7F7';
        dispatch(stateAction.updateState({ currentFormTitle: "Found" }));
        if (!tag) {
            navigate("/tag")
        }else {
            ITEM_SERVICE.getItemByTag(tag, onGetItems);
        }
    }, []);

    const onGetItems = (response) => {
        if (response && response.length > 0) {
            var item = response[0].data();
            console.log("item reunite", item);
            console.log("item userId", item.userId);
            setTagOwnerId(item.userId)
            setItemName(item.name);
        }
    }


    const onSendSMS = (response) => { 
        console.log("SMS sent resp", response)
    }
 
    const onGetUser = (resp) => {
      if (resp) {
        console.log("onGetUser foundReuinite", resp);
        const item = resp.docs[0].data();
        
        CHAT_SERVICE.createChatDoc(
          {
            id: tagOwnerId,
            name: name,
            email: item.email,
          },
          onCreateChatDoc
        );
    
        if (item.mobileNumber) {
          SMS_ENDPOINTS.sendSMS(
            {
              To: item.mobileNumber,
              From: '+447441368636',
              Body: 'Your item was found!',
            },
            onSendSMS
          );
        }
      }
    };
    
    const SMS_ENDPOINTS = {
      sendSMS: async (smsData, callback) => {
        try {
          const params = new URLSearchParams();
          params.append('To', smsData.To);
          params.append('From', smsData.From);
          params.append('Body', smsData.Body);
    
          const response = await API_SERVICE.post('/Messages.json', params);
          console.log('SMS sent successfully:', response.data);
    
          if (callback) callback(null, response.data);
        } catch (error) {
          console.error('Error sending SMS:', error);
          if (callback) callback(error, null);
        }
      },
    };

    const onSendEmail = (response) => {
        //TODO 
        console.log("tagOwnerId", tagOwnerId);  
        navigate("/found/thank-you");
    }

    

    const onCreateChatDoc = (resp, itemEmail) => {
        var emailChat = "";
        if(resp && resp.id){
           emailChat="backho.me/chat?chatId="+resp.id;
        }

        var generatedEmail = {
            to: itemEmail,
            message: {
                subject: 'Your item has been found! ' + itemName,
                html: `
                <!doctype html>
                    <html lang="en">
                    <head>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                        <title>Simple Transactional Email</title>
                        <style media="all" type="text/css">
                        /* -------------------------------------
                        GLOBAL RESETS
                    ------------------------------------- */
                        
                        body {
                        font-family: Arial, sans-serif;
                        -webkit-font-smoothing: antialiased;
                        font-size: 16px;
                        line-height: 1.3;
                        -ms-text-size-adjust: 100%;
                        -webkit-text-size-adjust: 100%;
                        }
                        
                        table {
                        border-collapse: separate;
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        width: 100%;
                        }
                        
                        table td {
                        font-family: Arial, sans-serif;
                        font-size: 16px;
                        vertical-align: top;
                        }
                         
                        
                        body {
                        background-color: #f4f5f6;
                        margin: 0;
                        padding: 0;
                        }
                        
                        .body {
                        background-color: #f4f5f6;
                        width: 100%;
                        }
                        
                        .container {
                        margin: 0 auto !important;
                        max-width: 600px;
                        padding: 0;
                        padding-top: 24px;
                        width: 600px;
                        }
                        
                        .content {
                        box-sizing: border-box;
                        display: block;
                        margin: 0 auto;
                        max-width: 600px;
                        padding: 0;
                        } 
                        
                        .main {
                        background: #c9e4dc;
                        border: 1px solid #f7f7f7;
                        border-radius: 16px;
                        width: 100%;
                        }
                        
                        .wrapper {
                        box-sizing: border-box;
                        padding: 24px;
                        }
                        
                        .footer {
                        clear: both;
                        padding-top: 24px;
                        text-align: center;
                        width: 100%;
                        }
                        
                        .footer td,
                        .footer p,
                        .footer span,
                        .footer a {
                        color: #e2e4e3;
                        font-size: 16px;
                        text-align: center;
                        } 
                        
                        p {
                        font-family: Arial, sans-serif;
                        font-size: 16px;
                        font-weight: normal;
                        margin: 0;
                        margin-bottom: 16px;
                        }
                        
                        a {
                        color: #1d1d1b;
                        text-decoration: underline;
                        } 
                        
                        .btn {
                        box-sizing: border-box;
                        min-width: 100% !important;
                        width: 100%;
                        }
                        
                        .btn > tbody > tr > td {
                        padding-bottom: 16px;
                        }
                        
                        .btn table {
                        width: auto;
                        }
                        
                        .btn table td {
                        background-color: #ffffff;
                        border-radius: 4px;
                        text-align: center;
                        }
                        
                        .btn a {
                        background-color: #1d1d1b;
                        border: solid 2px #1d1d1b;
                        border-radius: 4px;
                        box-sizing: border-box;
                        color: #1d1d1b;
                        cursor: pointer;
                        display: inline-block;
                        font-size: 16px;
                        font-weight: bold;
                        margin: 0;
                        padding: 12px 24px;
                        text-decoration: none;
                        text-transform: capitalize;
                        }
                        
                        .btn-primary table td {
                        background-color: #1d1d1b;
                        }
                        
                        .btn-primary a {
                        background-color: #1d1d1b;
                        border-color: #1d1d1b;
                        color: #f7f7f7;
                        }
                        
                        @media all {
                        .btn-primary table td:hover {
                            background-color: #f7f7f7 !important;
                        }
                        .btn-primary a:hover {
                            background-color: #f7f7f7 !important;
                            border-color: #f7f7f7 !important;
                        }
                        } 
                        
                        .last {
                        margin-bottom: 0;
                        }
                        
                        .first {
                        margin-top: 0;
                        }
                        
                        .align-center {
                        text-align: center;
                        }
                        
                        .align-right {
                        text-align: right;
                        }
                        
                        .align-left {
                        text-align: left;
                        }
                        
                        .text-link {
                        color: #0867ec !important;
                        text-decoration: underline !important;
                        }
                        
                        .clear {
                        clear: both;
                        }
                        
                        .mt0 {
                        margin-top: 0;
                        }
                        
                        .mb0 {
                        margin-bottom: 0;
                        }
                        
                        .preheader {
                        color: transparent;
                        display: none;
                        height: 0;
                        max-height: 0;
                        max-width: 0;
                        opacity: 0;
                        overflow: hidden;
                        mso-hide: all;
                        visibility: hidden;
                        width: 0;
                        }
                        
                        .powered-by a {
                        text-decoration: none;
                        }
                         
                        
                        @media only screen and (max-width: 640px) {
                        .main p,
                        .main td,
                        .main span {
                            font-size: 16px !important;
                        }
                        .wrapper {
                            padding: 8px !important;
                        }
                        .content {
                            padding: 0 !important;
                        }
                        .container {
                            padding: 0 !important;
                            padding-top: 8px !important;
                            width: 100% !important;
                        }
                        .main {
                            border-left-width: 0 !important;
                            border-radius: 0 !important;
                            border-right-width: 0 !important;
                        }
                        .btn table {
                            max-width: 100% !important;
                            width: 100% !important;
                        }
                        .btn a {
                            font-size: 16px !important;
                            max-width: 100% !important;
                            width: 100% !important;
                        }
                        } 
                        
                        @media all {
                        .ExternalClass {
                            width: 100%;
                        }
                        .ExternalClass,
                        .ExternalClass p,
                        .ExternalClass span,
                        .ExternalClass font,
                        .ExternalClass td,
                        .ExternalClass div {
                            line-height: 100%;
                        }
                        .apple-link a {
                            color: inherit !important;
                            font-family: inherit !important;
                            font-size: inherit !important;
                            font-weight: inherit !important;
                            line-height: inherit !important;
                            text-decoration: none !important;
                        }
                        #MessageViewBody a {
                            color: inherit;
                            text-decoration: none;
                            font-size: inherit;
                            font-family: inherit;
                            font-weight: inherit;
                            line-height: inherit;
                        }
                        }
                        </style>
                    </head>
                    <body>
                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
                        <tr>
                            <td>&nbsp;</td>
                            <td class="container">
                            <div class="content">

                                <!-- START CENTERED WHITE CONTAINER -->
                                <span class="preheader">We have found your lost item!</span>
                            
                                <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="main">
                                <!-- START MAIN CONTENT AREA -->
                                <tr>
                                    <td class="wrapper">
                                        <center><img src="https://backho.me/static/media/bkhome-logo-green.d8ef44e95542a664bab8b1711be0039d.svg" alt=""/></center><br>

                                    <h3>Hi - We have some great news!</h3>

                    <p>We hope this email finds you well! <br>We're reaching out from backho.me with some wonderful news: <br>
                    your ${itemName} has found its way backho.me and is just a message away.</p><br>
                                    <p>Finders Details:</p>
                                    <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="main">
                                        <tbody>
                                        <tr>
                                            <td align="left">
                                            <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                    <tr>
                                                    <td><p>Name:</p></td>
                                                    <td><p>${name} </p></td>
                                                    </tr>
                                                                                    <tr>
                                                    <td><p>Email:</p></td>
                                                    <td><p>${email} </p></td>
                                                    </tr>
                                                    <tr>
                                                    <td><p>Mobile:</p></td>
                                                    <td><p>${phoneNumber} </p></td>
                                                    </tr>
                                                    <tr>
                                                    <td><p>Address:</p></td>
                                                    <td><p>${address} </p></td>
                                                    </tr>
                                                    <tr>
                                                    <td><p>Found Location:</p></td>
                                                    <td><p> ${emailText} </p></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table><br>

                                    <p>${name} has kindly taken the time to report your item as found and are waiting to return it to its rightful owner – that's you!</p>
                                    
                                    <p>For your convenience and security, we've set up a direct webchat link for you to contact ${name}. This enables you to arrange the return of your item in the most suitable manner for both parties.<br>
                    <br>
                    Simply click the button below to start the chat:</p>
                                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary">
                                        <tbody>
                                        <tr>
                                            <td align="left">
                                            <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                    <tr>
                                                    <td> <a href="${emailChat}" target="_blank">${emailChat}</a> </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                    <p>We're thrilled to have played a part in making your day a little better and hope the return process is smooth and swift. If you encounter any issues or have questions, please don't hesitate to reach out to us.</p><br>
                    <p>Warm regards,</p>

                    <p>The backho.me Team</p><br>
                    <br>
                    <h5>P.S. We love hearing back from our community. Once your item is safely back with you, feel free to share your story with us.</h5>

                                    </td>
                                </tr> 
                                </table>
                    
                                <div class="footer">
                                <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                    <tr>
                                    <td class="content-block">
                                        <span class="apple-link">NXTGN LTD trading as backho.me</span>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td class="content-block powered-by">
                                        <p>Powered by backho.me</p>
                                    </td>
                                    </tr>
                                </table>
                                </div> 
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                        </table>
                    </body>
                    </html>
                `,
            },
        } 
        EMAIL_SERVICE.sendEmail(generatedEmail, onSendEmail);


        
        var foundUserEmail ={
            to: email,
            message: {
                subject: 'Thank you for finding ' + itemName,
                html: `
                <!doctype html>
<html lang="en">
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <title>Backho.me Item Finder</title>
    <style media="all" type="text/css">
    /* -------------------------------------
    GLOBAL RESETS
------------------------------------- */
    
    body {
      font-family: Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      font-size: 16px;
      line-height: 1.3;
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
    }
    
    table {
      border-collapse: separate;
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
      width: 100%;
    }
    
    table td {
      font-family: Arial, sans-serif;
      font-size: 16px;
      vertical-align: top;
    }
    /* -------------------------------------
    BODY & CONTAINER
------------------------------------- */
    
    body {
      background-color: #f4f5f6;
      margin: 0;
      padding: 0;
    }
    
    .body {
      background-color: #f4f5f6;
      width: 100%;
    }
    
    .container {
      margin: 0 auto !important;
      max-width: 600px;
      padding: 0;
      padding-top: 24px;
      width: 600px;
    }
    
    .content {
      box-sizing: border-box;
      display: block;
      margin: 0 auto;
      max-width: 600px;
      padding: 0;
    }
    /* -------------------------------------
    HEADER, FOOTER, MAIN
------------------------------------- */
    
    .main {
      background: #c9e4dc;
      border: 1px solid #f7f7f7;
      border-radius: 16px;
      width: 100%;
    }
    
    .wrapper {
      box-sizing: border-box;
      padding: 24px;
    }
    
    .footer {
      clear: both;
      padding-top: 24px;
      text-align: center;
      width: 100%;
    }
    
    .footer td,
    .footer p,
    .footer span,
    .footer a {
      color: #e2e4e3;
      font-size: 16px;
      text-align: center;
    }
    /* -------------------------------------
    TYPOGRAPHY
------------------------------------- */
    
    p {
      font-family: Arial, sans-serif;
      font-size: 16px;
      font-weight: normal;
      margin: 0;
      margin-bottom: 16px;
    }
    
    a {
      color: #1d1d1b;
      text-decoration: underline;
    }
    /* -------------------------------------
    BUTTONS
------------------------------------- */
    
    .btn {
      box-sizing: border-box;
      min-width: 100% !important;
      width: 100%;
    }
    
    .btn > tbody > tr > td {
      padding-bottom: 16px;
    }
    
    .btn table {
      width: auto;
    }
    
    .btn table td {
      background-color: #ffffff;
      border-radius: 4px;
      text-align: center;
    }
    
    .btn a {
      background-color: #1d1d1b;
      border: solid 2px #1d1d1b;
      border-radius: 4px;
      box-sizing: border-box;
      color: #1d1d1b;
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      margin: 0;
      padding: 12px 24px;
      text-decoration: none;
      text-transform: capitalize;
    }
    
    .btn-primary table td {
      background-color: #1d1d1b;
    }
    
    .btn-primary a {
      background-color: #1d1d1b;
      border-color: #1d1d1b;
      color: #f7f7f7;
    }
    
    @media all {
      .btn-primary table td:hover {
        background-color: #f7f7f7 !important;
      }
      .btn-primary a:hover {
        background-color: #f7f7f7 !important;
        border-color: #f7f7f7 !important;
      }
    }
    
    /* -------------------------------------
    OTHER STYLES THAT MIGHT BE USEFUL
------------------------------------- */
    
    .last {
      margin-bottom: 0;
    }
    
    .first {
      margin-top: 0;
    }
    
    .align-center {
      text-align: center;
    }
    
    .align-right {
      text-align: right;
    }
    
    .align-left {
      text-align: left;
    }
    
    .text-link {
      color: #0867ec !important;
      text-decoration: underline !important;
    }
    
    .clear {
      clear: both;
    }
    
    .mt0 {
      margin-top: 0;
    }
    
    .mb0 {
      margin-bottom: 0;
    }
    
    .preheader {
      color: transparent;
      display: none;
      height: 0;
      max-height: 0;
      max-width: 0;
      opacity: 0;
      overflow: hidden;
      mso-hide: all;
      visibility: hidden;
      width: 0;
    }
    
    .powered-by a {
      text-decoration: none;
    }
    
    /* -------------------------------------
    RESPONSIVE AND MOBILE FRIENDLY STYLES
------------------------------------- */
    
    @media only screen and (max-width: 640px) {
      .main p,
      .main td,
      .main span {
        font-size: 16px !important;
      }
      .wrapper {
        padding: 8px !important;
      }
      .content {
        padding: 0 !important;
      }
      .container {
        padding: 0 !important;
        padding-top: 8px !important;
        width: 100% !important;
      }
      .main {
        border-left-width: 0 !important;
        border-radius: 0 !important;
        border-right-width: 0 !important;
      }
      .btn table {
        max-width: 100% !important;
        width: 100% !important;
      }
      .btn a {
        font-size: 16px !important;
        max-width: 100% !important;
        width: 100% !important;
      }
    }
    /* -------------------------------------
    PRESERVE THESE STYLES IN THE HEAD
------------------------------------- */
    
    @media all {
      .ExternalClass {
        width: 100%;
      }
      .ExternalClass,
      .ExternalClass p,
      .ExternalClass span,
      .ExternalClass font,
      .ExternalClass td,
      .ExternalClass div {
        line-height: 100%;
      }
      .apple-link a {
        color: inherit !important;
        font-family: inherit !important;
        font-size: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
        text-decoration: none !important;
      }
      #MessageViewBody a {
        color: inherit;
        text-decoration: none;
        font-size: inherit;
        font-family: inherit;
        font-weight: inherit;
        line-height: inherit;
      }
    }
    </style>
  </head>
  <body>
    <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
      <tr>
        <td>&nbsp;</td>
        <td class="container">
          <div class="content">

            <!-- START CENTERED WHITE CONTAINER -->
            <span class="preheader">Backho.me - Webchat link</span>
        
            <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="main">
              <!-- START MAIN CONTENT AREA -->
              <tr>
                <td class="wrapper">
                    <center><img src="https://backho.me/static/media/bkhome-logo-green.d8ef44e95542a664bab8b1711be0039d.svg" alt=""/></center><br>

                  <h3>Hi - Thankyou for being an amazing human and helping returned someones item backho.me</h3>

                    <p>We have shared the following details with the owner:</p><br>
                  <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="main">
                    <tbody>
                      <tr>
                        <td align="left">
                          <table role="presentation" border="0" cellpadding="0" cellspacing="0">
				<tbody>
                                                    <tr>
                                                    <td><p>Name:</p></td>
                                                    <td><p>${name} </p></td>
                                                    </tr>
                                                                                    <tr>
                                                    <td><p>Email:</p></td>
                                                    <td><p>${email} </p></td>
                                                    </tr>
                                                    <tr>
                                                    <td><p>Mobile:</p></td>
                                                    <td><p>${phoneNumber} </p></td>
                                                    </tr>
                                                    <tr>
                                                    <td><p>Address:</p></td>
                                                    <td><p>${address} </p></td>
                                                    </tr>
                                                    <tr>
                                                    <td><p>Found Location:</p></td>
                                                    <td><p> ${emailText} </p></td>
                                                    </tr>
                                                </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table><br>

                  <p>Someone should be in touch soon, or they might already be reaching out on our webchat service.</p>
                 
                  <p>For your convenience and security, we've set up a direct webchat link for you to contact the owner.<br>
<br>
 Simply click the button below to start the chat:</p>
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary">
                    <tbody>
                      <tr>
                        <td align="left">
                          <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                            <tbody>
                                <tr>
                                <td> <a href="${emailChat}" target="_blank">${emailChat}</a> </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                    <p>Don't worry we will keep your data safe, only the owner of the item you have found will see your data and we will delet it as soon as its been returned.<br>
Making sure your data is safe is critical to us. If its not marked as found we will delete your details after a period to keep it safe.</p><br>
<p>If you encounter any issues or have questions, please don't hesitate to reach out to us.</p><br>
<p>Warm regards,</p>

<p>The backho.me Team</p><br>
<br>
<h5>P.S. We love hearing back from our community. Please let us know how you found the experience</h5>

                </td>
              </tr>

              <!-- END MAIN CONTENT AREA -->
              </table>

            <!-- START FOOTER -->
            <div class="footer">
              <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                <tr>
                  <td class="content-block">
                    <span class="apple-link">NXTGN LTD trading as backho.me</span>
                  </td>
                </tr>
                <tr>
                  <td class="content-block powered-by">
                    <p>Powered by backho.me</p>
                  </td>
                </tr>
              </table>
            </div>

            <!-- END FOOTER -->
            
<!-- END CENTERED WHITE CONTAINER --></div>
        </td>
        <td>&nbsp;</td>
      </tr>
    </table>
  </body>
</html>
                `,
            },
        } 

        EMAIL_SERVICE.sendEmail(foundUserEmail, onSendDudEmail); 

    }

    const onSendDudEmail = () => {

    }
    
    const submitForm = () => {
        if (email && email.length > 3 && tag) {
            USER_SERVICE.getUserDocById(tagOwnerId, onGetUser);
        } else {
            setErrorMessage("Invalid email");
        }
    }

    const handleInformOwner = (e) => {
        e.preventDefault();
        submitForm();
    }

    return (
        <div className='container'>
            <div>
                <h3>Reuniting a FOUND item</h3>
                <p>You’re a good human being! Enter your details below so we can pass them on to the owner and organise reuniting the lost item.</p>
                <form className="white-form" style={{ marginTop: '2em' }}>
                    <div className="input-container">
                        <input placeholder="NAME" value={name} type="name" id="name" onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="input-container">
                        <input placeholder="EMAIL" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="input-container">
                        <input placeholder="PHONE NUMBER" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                    </div>
                    <div className="input-container">
                        <input placeholder="ADDRESS" value={address} onChange={(e) => setAddress(e.target.value)} />
                    </div>
                    <div className="input-container">
                        <textarea type="textarea" placeholder="WHERE YOU FOUND THE ITEM" value={emailText} onChange={(e) => setEmailText(e.target.value)} />
                    </div>
                </form>
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                <button className="big-button-white" onClick={handleInformOwner}>Inform the Owner</button>
            </div>
        </div>
    );

}
